.socialmediaicon {
    width: 32px;
    height: 32px;
    margin: 0 20px;
    color: red;
    transition: transform 1s;
}

@media screen and (max-width: 600px) {
    .socialmediaicon {
        width: 7vw;
        height: 7vw;
        padding: 2%;
    }
}

@media screen and (max-height: 400px) {
    .socialmediaicon {
        width: 8vh;
        height: 8vh;
        padding: 2%;
    }
}

.socialmediaicon:hover {
    transform: scale(1.5);
}