.noticiaWrapper {
    background-color: #212121;
    font-family: Roboto, sans-serif;
    padding: 8px;
    margin: 5px;
    width: 32%;
}

@media screen and (max-width: 800px) {
    .noticiaWrapper {
        width: 45vw;
    }
}

@media screen and (max-width: 550px) {
    .noticiaWrapper {
        width: 85vw;
        margin: 0%;
    }
}

.noticiaWrapper:hover {
    background-color: #2a2a2a;
}

.noticia .title {
    font-size: 1.3rem;
    margin-bottom: 25px;
    max-height: 5rem;
    overflow: hidden
}

.noticia p {
    text-align: left;
    font-size: 1rem;
    margin: 5px;
    max-height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}


.text {
    margin: 20px;
    overflow: hidden;
}

.noticiapicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.noticiapicturewrapper {
    display: block;
    height: 230px;
}

@media screen and (max-width: 550) {
    .noticiapicturewrapper {
        height: 0vw;
    }
}


