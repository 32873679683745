/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.noticiasPanel {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-top: 25px;
}


.topo {
    font-size: 2rem;
    padding-bottom: 2%;
    font-family: Roboto, sans-serif;
    margin: 0 auto;
    max-width: 75rem;
}

.titulo {
    display: inline-block;
}

.border {
    border-bottom: 5px solid #af2121;
    position: absolute;
    display: inline-block;
    width: 5%;
}

.noticias {
    margin-top: 25px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}


.button {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    width: 150px;
}


@media screen and (max-width: 500px) {
    .button {
        width: 50%;
    }
    
}