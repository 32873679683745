.newPanel {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: Roboto, sans-serif;
    padding: 55px;
}

.title {
    font-size: 2rem;
    margin-bottom: 60px;
}

.title::after {
    content: "";
    border-bottom: 5px solid #af2121;
    display: block;
    width: 10%;
}

.text {
    margin-bottom: 60px;
    text-indent: 10px;
}

.image {
    border: 10px solid #181818;
    max-height: 700px;
    object-fit: cover;
}