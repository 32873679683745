.navPanel {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    background-color: #0000009f;
    color: #ffffff;
    height: 60px;
}

.items {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    align-items: center;
    margin-left: 4vw;
    width: 50%;
    text-transform: uppercase;
}

.navItems {
    display: flex;
    flex-direction: row;
}

.navItem {
    margin-right: 6vw;
}

.menuIcon {
    visibility: hidden;
    font-size: 1.5rem;
    cursor: pointer;
}


.rightRow {
    justify-content: flex-end;
    align-items: center;
    margin-right: 4vw;
}

@media screen and (max-width: 600px) {
    .leftRow{
        display: none;
    }
    .menuIcon {
        visibility: visible;
    }
}