.formItem {
    background-color: white;
    width: 650px;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid rgb(184, 184, 184);
    box-shadow: 0px -5px 0px 0px #18a33d;
}

@media screen and (max-width: 800px) {
    .formItem {
        width: 80%;
    }
}
