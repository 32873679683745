.logo {
    position: absolute;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    top: 25vh;
    left: 0;
    right: 0;
    text-align: center;
}


@media screen and (max-width: 1000px) {
    .logo {
        width: 500px;
    }
}

@media screen and (max-width: 600px) {
    .logo {
        width: 50%;
        top: 15vh
    }
}

.footer {
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}