.footer {
    width: 100%;
    padding: 50px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image {
    display: block;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}