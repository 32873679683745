a {
    text-decoration: none;
}

.button {
    width: 100%;
    height: 3rem;
    background-color: #ffffff;
    text-transform: uppercase;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    border: none;
    padding: 0;
    transition: 500ms;
}

.button:hover {
    background-color: #32A852;
    color: white;
    cursor: pointer;
}

.dark {
    width: 180px;
    height: 6vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #252525;
    text-transform: uppercase;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    border: none;
    transition: 500ms;
}

.dark:hover {
    cursor: pointer;
    background-color: #af2121;
}