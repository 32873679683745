.error {
    padding: 10%;
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.title {
    padding: 25px;

}