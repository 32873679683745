
.mediaIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* background: linear-gradient(180deg, #000000 30%, #181818 100%); */
    background-color: #000;
    padding-right: 2%;
    height: 7vh;
}

@media screen and (max-width: 600px) {
    .mediaIcons {
        justify-content: space-around;
        padding-right: 0;
    }
}