.item {
    box-sizing: content-box;
}

.link {
    text-decoration: none;
    color: white;
}

.item:after {
    display: block;
    content: '';
    border-bottom: 3px #af2121 solid;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
}

.item:hover:after {
    transform: scaleX(1);
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text img {
    margin-left: 10px;
    width: 24px;
    height: 24px;
}

.navIcon {
    margin-left: 5px;
}