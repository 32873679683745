.formWrapper {
    position: absolute;
    top: 15vh;
    
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-family: Roboto, sans-serif;
}

@media screen and (max-height: 650px) {
    .formWrapper {
        top: 100vh;
        background-color: #000;
    }
}

@media screen and (max-width: 600px) {
    .formWrapper {
        top: 47vh;
        background-color: #000;
    }
}



.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    margin-left: 3px;
    display: block;
}

.textField {
    width: 98%;
    height: 28px;
    resize: none;
    border-radius: 0px;
    margin-top: 1vh;
    border: none;
    border-bottom: 1px solid rgb(150, 150, 150);
    
}

.textField:focus {
    outline: none;
}

.textArea {
    height: 120px;
    border: 1px solid rgb(150, 150, 150);
    border-radius: 5px;
    padding: 5px;
    font-family: Roboto, sans-serif;
}

.submitWrapper {
    width: 120px;
    margin-bottom: 20px;
}